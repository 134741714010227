<template>
  <ContentWrapper>
    <h4>
      {{ knowledgeBaseId ? `${$t('system.edit_knowledge')} #${knowledgeBaseId}` : $t('system.create_knowledge') }}</h4>
    <el-divider />
    <el-form
        :model="form"
        :size="size"
        label-position="top"
        ref="form"
    >
      <div class="row">
        <div class="col-8">
          <el-form-item
              :label="$t('system.title')"
              required
          >
            <el-input
                name="title"
                v-model="form.title"
            />
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="$t('system.files')">
            <el-upload
                :action="''"
                :auto-upload="false"
                :file-list="filesToUpload"
                :on-change="onFilesChanges"
                :on-remove="onFileRemove"
                multiple
                ref="uploader"
            >
              <el-button
                  size="mini"
                  slot="trigger"
                  type="info"
              >
                {{ $t('system.select_files') }}
              </el-button>
            </el-upload>
            <FilesList
                :downloadProgress="fileDownloadProgress"
                :files="files"
                @download="downloadFile"
                @remove="removeFile"
                download
                remove
            />
          </el-form-item>
        </div>
        <div class="col-12">
          <el-form-item :label="$t('system.description')">
            <vue-editor v-model="form.description" />
          </el-form-item>
        </div>
      </div>
      <el-form-item>
        <div class="box-button">
          <el-button
              :loading="saveLoading"
              @click="save"
              class="mr-2"
              type="primary"
          >
            {{ $t('system.save') }}
          </el-button>
          <router-link :to="{name:'KnowledgeBaseIndex'}">
            <el-button>{{ $t('system.cancel') }}</el-button>
          </router-link>
        </div>
      </el-form-item>
    </el-form>
  </ContentWrapper>
</template>

<script>
import axios from 'axios';
import {cloneDeep, remove} from 'lodash';
import {VueEditor} from 'vue2-editor';
// import notification from '../../notification/notify'
import ContentWrapper from '../../components/HOCs/ContentWrapper.vue';
import FilesList from '../../components/FilesList/FilesList.vue';
import {checkFileSize, downloadFile} from '../../helpers/files';
import notification from '../../notification/notify';

export default {
  name: 'KnowledgeBaseForm',
  components: {
    FilesList,
    ContentWrapper,
    VueEditor,
  },
  props: ['knowledgeBaseId'],
  data() {
    return {
      saveLoading: false,
      size: 'medium',
      form: {},
      backend_errors: {},
      initialize: 'knowledge_base/create',
      method: 'post',
      files: [],
      filesToUpload: [],
      filesToDelete: [],
      fileDownloadProgress: {
        id: null,
        progress: 0,
      },
    };
  },
  mounted() {
    if (this.knowledgeBaseId) {
      this.fetchEditData();
    }
  },
  methods: {
    onFilesChanges(file, fileList) {
      if (!checkFileSize(file, 5)) {
        notification.notify(
            file.name,
            this.$t('notify.file_is_too_large'),
            'warning');
        remove(fileList, (item) => {
          return item.uid === file.uid;
        });
      }
      this.setNewFilesList(fileList);
    },
    onFileRemove(file, fileList) {
      this.setNewFilesList(fileList);
    },
    removeFile(file) {
      this.files.splice(this.files.findIndex(item => item.id === file.id), 1);
      this.filesToDelete.push(file.id);
    },
    setNewFilesList(fileList) {
      this.filesToUpload = cloneDeep(fileList);
    },
    async downloadFile(item) {
      this.fileDownloadProgress.id = item.id;
      //TODO change endpoint
      const file = await axios.get(`/knowledge_base/attachment/${item.id}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.fileDownloadProgress.progress = Math.round((progressEvent.loaded * 100) / item.filesize);
        },
      });
      setTimeout(() => {
        this.fileDownloadProgress = {
          id: null,
          progress: 0,
        };
        downloadFile(file, item.filename);
      }, 700);
    },
    async fetchEditData() {
      const {data} = await axios.get(`knowledge_base/${this.knowledgeBaseId}/edit`);
      this.form = data.form;
      this.files = data.attachments;
    },
    async uploadFiles(knowledgeBaseId) {
      const requests = this.filesToUpload.map(file => {
        const formData = new FormData();
        formData.append('file', file.raw);
        return axios.post(
            `knowledge_base/${knowledgeBaseId}/upload`,
            formData,
            {headers: {'Content-Type': `multipart/form-data`}});
      });
      return await Promise.all(requests);
    },
    async removeFiles() {
      const requests = this.filesToDelete.map(async id => {
        return axios.delete(`knowledge_base/${id}/destroy`);
      });

      return await Promise.all(requests);
    },
    async save() {
      try {
        this.saveLoading = true;
        let endpoint = 'knowledge_base';
        if (this.knowledgeBaseId) {
          endpoint = `${endpoint}/${this.knowledgeBaseId}`;
        }
        const {data} = await axios.post(endpoint, this.form);
        console.log('data', data);
        await this.uploadFiles(data.id || this.knowledgeBaseId);
        await this.removeFiles();
        await this.$router.push({
          name: 'KnowledgeBaseShow',
          params: {
            knowledgeBaseId: data.id ? data.id : this.knowledgeBaseId,
          },
        });
      } catch (error) {
        console.log('error', error);
        this.backend_errors = error.response.data.errors;
      } finally {
        this.saveLoading = false;
      }
    },
  },
};
</script>
